import dixSite from '../../images/dix_site.webp';

import { ReactComponent as AfterEffects } from '../../images/icons/aftereffects.svg';
import { ReactComponent as Figma } from '../../images/icons/figma.svg';
import { ReactComponent as Firebase } from '../../images/icons/firebase.svg';
import { ReactComponent as Git } from '../../images/icons/git.svg';
import { ReactComponent as Html5 } from '../../images/icons/html5.svg';
import { ReactComponent as Js } from '../../images/icons/js.svg';
import { ReactComponent as React } from '../../images/icons/react.svg';
import { ReactComponent as Photoshop } from '../../images/icons/photoshop.svg';
import { ReactComponent as Sass } from '../../images/icons/sass.svg';
import { ReactComponent as Tailwind } from '../../images/icons/tailwind.svg';
import { ReactComponent as Toggl } from '../../images/icons/toggl.svg';
import { ReactComponent as Trello } from '../../images/icons/trello.svg';
import Button from '../buttons/Button';
/**
 *
 * @returns DixInTheDark completed work section
 */
const Dix = () => {
	return (
		<div className="pb-16">
			<h3 className="text-2xl font-medium pb-4">Dix in the Dark</h3>
			<p className="pb-4 text-sm">
				A portfolio-esq app for an up-and-coming live play DnD group.
			</p>
			<p className="pb-4 text-sm">
				The site design was entirely custom to client specifications,
				and built with the capability to deploy as a PWA. On top of
				design and development, I also maintain the app and report on
				monthly site performance and opportunities for improvement.
			</p>
			<img
				src={dixSite}
				alt="recent work"
				className="self-center rounded-xl mb-4"
			/>

			<div className="tech-stack pb-4">
				<h4 className="text-xl pb-4">Tech Stack</h4>
				<div className="grid grid-cols-5 md:grid-cols-6 gap-x-8 gap-y-5">
					<div className="flex flex-col items-center">
						<AfterEffects className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">
							After Effects
						</p>
					</div>
					<div className="flex flex-col items-center">
						<Figma className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Figma</p>
					</div>
					<div className="flex flex-col items-center">
						<Firebase className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Firebase</p>
					</div>
					<div className="flex flex-col items-center">
						<Git className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Git & Gitlab</p>
					</div>
					<div className="flex flex-col items-center">
						<Html5 className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">HTML5</p>
					</div>
					<div className="flex flex-col items-center">
						<Js className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">ES6</p>
					</div>
					<div className="flex flex-col items-center">
						<React className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">React</p>
					</div>
					<div className="flex flex-col items-center">
						<Photoshop className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Photoshop</p>
					</div>
					<div className="flex flex-col items-center">
						<Sass className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Sass</p>
					</div>
					<div className="flex flex-col items-center">
						<Tailwind className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Tailwind CSS</p>
					</div>
					<div className="flex flex-col items-center">
						<Toggl className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Toggl</p>
					</div>
					<div className="flex flex-col items-center">
						<Trello className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-xs pt-2">Trello</p>
					</div>
				</div>
			</div>

			<div className="project-links flex flex-col items-center">
				<div className="pb-6">
					<Button type="light-teal">
						<a
							href="https://www.dixinthedark.com"
							target="_blank"
							rel="noreferrer"
						>
							Visit the live site
						</a>
					</Button>
				</div>
				<Button type="light-teal">
					<a
						href="https://github.com/Pseudo-Solutions-LLC/DixintheDark"
						target="_blank"
						rel="noreferrer"
					>
						Check out the code
					</a>
				</Button>
			</div>
		</div>
	);
};

export default Dix;
