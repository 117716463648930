import { collection, addDoc } from '@firebase/firestore';
import { useEffect } from 'react';
import { db } from '../firebase/firestore';
import Button from './buttons/Button';

/**
 *
 * @returns Contact me form
 */

const Contact = () => {
	useEffect(() => {
		document
			.getElementById('contact-form')
			.addEventListener('submit', submitForm);
	});

	const submitForm = async (e) => {
		e.preventDefault();

		let name = getInput('name');
		let email = getInput('email');
		let message = getInput('message');

		try {
			await addDoc(collection(db, 'messages'), {
				name,
				email,
				message,
			});
		} catch (e) {
			console.error('There was an error writing to firestore: ', e);
			document.getElementById('alert-error').classList.remove('hidden');
		}

		document.getElementById('alert-success').classList.remove('hidden');

		document.getElementById('name').value = '';
		document.getElementById('email').value = '';
		document.getElementById('message').value = '';
	};

	const getInput = (id) => {
		return document.getElementById(id).value;
	};

	return (
		<div id="contact" className="flex justify-center pt-16">
			<form
				id="contact-form"
				className="flex flex-col justify-center px-6 text-white md:max-w-2xl"
			>
				<h2 className="font-custom font-semibold text-4xl self-center pb-8">
					Contact
				</h2>

				<p className="text-sm pb-8">
					I respond fastest to e-mails, but am open to messages from
					any of the social links listed here, or from the contact
					form below.
				</p>

				<div className="pb-8 flex flex-col items-center">
					<div className="pb-4">
						<Button type="contact">
							<a
								href="https://www.linkedin.com/in/garrettspence/"
								target="_blank"
								rel="noreferrer"
							>
								Message me on LinkedIn
							</a>
						</Button>
					</div>
					<div className="pb-4">
						<Button type="contact">
							<a
								href="mailto:garrettwspence@gmail.com?subject=Portfolio Contact"
								target="_blank"
								rel="noreferrer"
							>
								Send me an email
							</a>
						</Button>
					</div>
					{/* <Button type="contact">
						<a
							href="https://twitter.com/BruncheonBro"
							target="_blank"
							rel="noreferrer"
						>
							@BruncheonBro on Twitter
						</a>
					</Button> */}
				</div>

				<div className="form-alerts">
					<span
						id="alert-success"
						className="hidden px-4 bg-green-500 w-full h-12 flex justify-center items-center rounded-lg mb-4"
					>
						Your message has been sent!
					</span>
					<span
						id="alert-error"
						className=" hidden px-4 bg-red-600 w-full h-12 flex justify-center items-center rounded-lg mb-4"
					>
						There was an error sending your message.
					</span>
				</div>

				<div className="flex flex-col w-full text-sm self-center pb-4">
					<label className="pb-2">Your name or organization</label>
					<input
						id="name"
						type="text"
						className="rounded-lg h-12 px-4 text-black focus:outline-none focus:ring focus:ring-teal"
						placeholder="Jester Lavorre"
						required
					/>
				</div>

				<div className="flex flex-col w-full text-sm self-center pb-4">
					<label className="pb-2">Your e-mail address</label>
					<input
						id="email"
						type="email"
						className="rounded-lg h-12 px-4 text-black focus:outline-none focus:ring focus:ring-light-teal"
						placeholder="jester@traveler.com"
						required
					/>
				</div>
				<div className="flex flex-col w-full text-sm self-center pb-10">
					<label className="pb-2">Your message</label>
					<textarea
						id="message"
						type="text"
						className="rounded-lg text-black h-28 px-4 py-2 focus:outline-none focus:ring focus:ring-teal"
						placeholder="OMG Hi! Have you heard about the traveler..."
						required
					/>
				</div>
				<div className="text-center self-center">
					<Button type="contact">
						<span type="submit">Send Message</span>
					</Button>
				</div>

				{/* <button
					type="submit"
					className="btn bg-light-teal text-center self-center text-3xl"
				>
					Send Message
				</button> */}
			</form>
		</div>
	);
};

export default Contact;
