import anime from 'animejs';
import { useEffect, useState } from 'react';

// TODO: make the menu animation interruptive
// TODO: update menu background to fill screen
const MobileNav = () => {
	const [isOpen, setOpen] = useState('closed');
	const animationTime = 500;

	useEffect(() => {
		document.querySelectorAll('a[href^="#"]').forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();

				if (isOpen !== 'closed') {
					closeMenu();
				}

				document.querySelector(el.getAttribute('href')).scrollIntoView({
					behavior: 'smooth',
				});
			});
		});
	});

	const openMenu = () => {
		setOpen('opening');

		let openAnimation = anime.timeline({
			begin: () => {
				document
					.getElementsByClassName('open-menu')[0]
					.classList.add('z-10');
				document
					.getElementsByClassName('open-menu')[0]
					.classList.remove('hidden');
				document
					.getElementsByClassName('menu-links')[0]
					.classList.add('flex');
				document
					.getElementsByClassName('menu-links')[0]
					.classList.remove('hidden');
				document
					.getElementsByClassName('menu-links')[0]
					.classList.add('z-20');
			},
		});

		openAnimation.add({
			targets: '.menuWave1',
			d: [
				{
					value: 'M450 450C414.6 452.8 379.2 455.6 349.9 438.7C320.5 421.9 297.2 385.3 278.2 356.8C259.1 328.2 244.4 307.6 211.2 299.4C178.1 291.3 126.5 295.5 108.3 272.5C90.1 249.4 105.2 199 111.2 163.1C117.3 127.3 114.2 106 94.2 81.2C74.1 56.5 37.1 28.2 0 0H450V450Z',
				},
				{
					value: 'M450 821.5C414.6 824.3 390.3 731.4 361 714.5C331.6 697.7 265.793 747.799 234.5 714.5C195.5 673 223.2 597.2 190 589C156.9 580.9 99.5606 580.5 64.5 536C29.4394 491.5 74.5 394.4 80.5 358.5C90.2772 300 7.82794e-06 266 1.83184e-05 200.5C1.17488e-05 168.5 -1.21992e-05 40 7.82794e-06 0H450V821.5Z',
				},
				{
					value: 'M450 900C415 900 385 900 352 900C319 900 293.5 900 267 900C231 900 214 900 181 900C148 900 145.5 900 116.5 900C87.5 900 80 900 51.5 900C23 900 21 900 7.91373e-06 900C1.34418e-06 868 -7.8304e-06 40 1.21968e-05 0H450V900Z',
				},
			],
			easing: 'linear',
			duration: animationTime,
		});
		openAnimation.add(
			{
				targets: '.menuWave2',
				d: [
					{
						value: 'M450 375C420.5 377.3 391 379.6 366.6 365.6C342.1 351.5 322.7 321.1 306.8 297.3C290.9 273.5 278.6 256.3 251 249.5C223.4 242.7 180.5 246.3 165.3 227.1C150.1 207.8 162.7 165.8 167.7 136C172.7 106.1 170.2 88.3 153.5 67.7C136.8 47 105.9 23.5 75 0H450V375Z',
					},
					{
						value: 'M450 753C420.5 755.3 398.4 670 374 656C349.5 641.9 289.4 679.8 273.5 656C257.6 632.2 262.1 558.3 234.5 551.5C206.9 544.7 131 534.918 103.5 500C76 465.082 146.5 341.5 131 305.5C115.5 269.5 -7.26576e-06 198 1.05349e-05 136.5C1.90734e-05 107 -7.5849e-06 26.5 1.05349e-05 0H450V753Z',
					},
					{
						value: 'M450 900C426.5 900 413 900 385 900C357 900 316.5 900 290 900C263.5 900 251 900 218.5 900C194 900 170.5 900 142 900C121.5 900 112.5 900 89.9999 900C57.9999 900 22.4999 900 -8.20299e-05 900C-0.000151226 875.5 -0.00010921 35 -0.000111594 0H450V900Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			50
		);
		openAnimation.add(
			{
				targets: '.menuWave3',
				d: [
					{
						value: 'M450 300C426.4 301.9 402.8 303.7 383.2 292.5C363.7 281.2 348.1 256.9 335.5 237.9C322.8 218.8 312.9 205.1 290.8 199.6C268.7 194.2 234.4 197 222.2 181.6C210.1 166.3 220.1 132.7 224.2 108.8C228.2 84.9 226.1 70.7 212.8 54.1C199.4 37.6 174.7 18.8 150 0H450V300Z',
					},
					{
						value: 'M450 657C426.4 658.9 422.1 595.2 402.5 584C383 572.7 331 636 303 605.5C275 575 301 523.563 268 515.5C235 507.437 186.68 503.5 159 468.5C131.32 433.5 190.5 351.5 177.5 289.5C164.5 227.5 -1.9322e-05 130 7.05136e-07 77.5C2.93312e-06 56.5 -1.67905e-06 25.5 7.05136e-07 0H450V657Z',
					},
					{
						value: 'M450 900C425.5 900 400 900 373.5 900C353 900 312.5 900 291 900C269.5 900 221.5 900 199 900C176.5 900 158 900 130.5 900C111 900 85.4999 900 59 900C39.9999 900 21.4999 900 -8.94581e-05 900C-0.000129784 880.5 -0.000135473 28 -9.08886e-05 0H450V900Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			100
		);
		openAnimation.add(
			{
				targets: '.menuWave4',
				d: [
					{
						value: 'M450 225C432.3 226.4 414.6 227.8 399.9 219.4C385.3 210.9 373.6 192.7 364.1 178.4C354.6 164.1 347.2 153.8 330.6 149.7C314 145.6 288.3 147.8 279.2 136.2C270.1 124.7 277.6 99.5 280.6 81.6C283.6 63.6 282.1 53 272.1 40.6C262.1 28.2 243.5 14.1 225 0H450V225Z',
					},
					{
						value: 'M450 551.5C432.3 552.9 420.2 506.4 405.5 498C390.9 489.5 340 542.8 330.5 528.5C321 514.2 315.1 455.1 298.5 451C281.9 446.9 221.635 475 197.5 444.5C173.365 414 236.5 277.1 197.5 231.5C158.5 185.9 5.05738e-07 72 5.05738e-07 21C5.05738e-07 7 -3.30896e-06 16.5 5.05738e-07 0H450V551.5Z',
					},
					{
						value: 'M450 900C434 900 390 900 372.5 900C355 900 309.5 900 274.5 900C254 900 223.5 900 194 900C178.5 900 135.5 900 112.5 900C97 900 85.5 900 63.5001 900C36.5 900 19.5 900 4.72097e-05 900C-2.40474e-05 874 7.39377e-06 26 4.29433e-06 0H450V900Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			150
		);
		openAnimation.add(
			{
				targets: '.menuWave5',
				d: [
					{
						value: 'M450 150C438.2 150.9 426.4 151.9 416.6 146.2C406.8 140.6 399.1 128.4 392.7 118.9C386.4 109.4 381.5 102.5 370.4 99.8C359.4 97.1 342.2 98.5 336.1 90.8C330 83.1 335.1 66.3 337.1 54.4C339.1 42.4 338.1 35.3 331.4 27.1C324.7 18.8 312.4 9.4 300 0H450V150Z',
					},
					{
						value: 'M450 470.5C438.2 471.4 421 413.714 400.5 402C380 390.286 379.5 479 355.5 462C331.5 445 358.5 383.838 334.5 378C310.5 372.162 262.136 416 245.5 395C228.864 374 293.5 274 271.5 220.5C249.5 167 168.5 153 128.5 111.5C88.5 70 41 11 32.5 0H450V470.5Z',
					},
					{
						value: 'M450 900C435 900 407.5 900 382.5 900C372.5 900 336 900 312.5 900C289 900 246 900 219.5 900C200 900 131.5 900 111.5 900C97.9999 900 58.5 900 45.9999 900C33.4999 900 17.4999 900 -3.38554e-05 900C-0.00021553 872 -9.41753e-06 37.5 -0.00012207 0H450V900Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			200
		);
		openAnimation.add(
			{
				targets: '.menuWave6',
				d: [
					{
						value: 'M450 75C444.1 75.5 438.2 75.9 433.3 73.1C428.4 70.3 424.5 64.2 421.4 59.5C418.2 54.7 415.7 51.3 410.2 49.9C404.7 48.5 396.1 49.3 393.1 45.4C390 41.6 392.5 33.2 393.5 27.2C394.5 21.2 394 17.7 390.7 13.5C387.4 9.4 381.2 4.7 375 0H450V75Z',
					},
					{
						value: 'M450 384.5C432 376.5 436 295 416 290.5C396 286 390.5 376.5 375.5 369.5C360.5 362.5 367.5 304.9 362 303.5C356.5 302.1 321.5 300.306 313.5 290.5C305.5 280.694 349 195.5 321.5 152C294 108.5 239 133.5 178.5 75C118 16.5 111.7 4.7 105.5 0H450V384.5Z',
					},
					{
						value: 'M450 900C433 900 409.5 900 377.5 900C355 900 323.5 900 287 900C259 900 226.5 900 193.5 900C168.5 900 153 900 119.5 900C97 900 81.5 900 53 900C35.5 900 26.5 900 -3.57628e-06 900C-0.000107288 862 -5.12004e-05 41.5 -0.00012207 0H450V900Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			250
		);
		openAnimation.add({
			targets: '.menu-links li',
			easing: 'spring(1, 70, 15, 20)',
			translateX: '-100vw',
		});

		openAnimation.finished.then(() => {
			setOpen('open');
		});
	};

	const closeMenu = () => {
		setOpen('closing');

		let closeAnimation = anime.timeline({
			targets: '.menu-links li',
			easing: 'spring(1, 100, 10, 5)',
			translateX: '100vw',
		});

		closeAnimation.add({
			begin: () => {
				document
					.getElementsByClassName('menu-links')[0]
					.classList.remove('flex');
				document
					.getElementsByClassName('menu-links')[0]
					.classList.add('hidden');
				document
					.getElementsByClassName('menu-links')[0]
					.classList.remove('z-20');
			},
			duration: 0,
		});

		closeAnimation.add({
			targets: '.menuWave6',
			d: [
				{
					value: 'M450 384.5C432 376.5 436 295 416 290.5C396 286 390.5 376.5 375.5 369.5C360.5 362.5 367.5 304.9 362 303.5C356.5 302.1 321.5 300.306 313.5 290.5C305.5 280.694 349 195.5 321.5 152C294 108.5 239 133.5 178.5 75C118 16.5 111.7 4.7 105.5 0H450V384.5Z',
				},
				{
					value: 'M450 75C444.1 75.5 438.2 75.9 433.3 73.1C428.4 70.3 424.5 64.2 421.4 59.5C418.2 54.7 415.7 51.3 410.2 49.9C404.7 48.5 396.1 49.3 393.1 45.4C390 41.6 392.5 33.2 393.5 27.2C394.5 21.2 394 17.7 390.7 13.5C387.4 9.4 381.2 4.7 375 0H450V75Z',
				},
				{
					value: 'M450 0.995264C449.921 1.0019 449.843 1.00721 449.777 0.97005C449.712 0.932894 449.66 0.851946 449.619 0.789576C449.576 0.725879 449.543 0.68076 449.469 0.662182C449.396 0.643604 449.281 0.65422 449.241 0.602466C449.2 0.55204 449.233 0.44057 449.247 0.360949C449.26 0.281328 449.253 0.234882 449.209 0.179147C449.165 0.12474 449.083 0.0623699 449 0H450V0.995264Z',
				},
			],
			easing: 'linear',
			duration: animationTime,
		});
		closeAnimation.add(
			{
				targets: '.menuWave5',
				d: [
					{
						value: 'M450 470.5C438.2 471.4 421 413.714 400.5 402C380 390.286 379.5 479 355.5 462C331.5 445 358.5 383.838 334.5 378C310.5 372.162 262.136 416 245.5 395C228.864 374 293.5 274 271.5 220.5C249.5 167 168.5 153 128.5 111.5C88.5 70 41 11 32.5 0H450V470.5Z',
					},
					{
						value: 'M450 150C438.2 150.9 426.4 151.9 416.6 146.2C406.8 140.6 399.1 128.4 392.7 118.9C386.4 109.4 381.5 102.5 370.4 99.8C359.4 97.1 342.2 98.5 336.1 90.8C330 83.1 335.1 66.3 337.1 54.4C339.1 42.4 338.1 35.3 331.4 27.1C324.7 18.8 312.4 9.4 300 0H450V150Z',
					},
					{
						value: 'M450 0.99538C449.921 1.00135 449.843 1.00799 449.777 0.970164C449.712 0.933003 449.661 0.852045 449.618 0.789005C449.576 0.725964 449.543 0.680176 449.469 0.66226C449.396 0.644343 449.281 0.653633 449.241 0.602537C449.2 0.551441 449.234 0.439958 449.247 0.360991C449.261 0.281361 449.254 0.234246 449.209 0.179832C449.165 0.124754 449.083 0.0623772 449 0H450V0.99538Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			50
		);
		closeAnimation.add(
			{
				targets: '.menuWave4',
				d: [
					{
						value: 'M450 551.5C432.3 552.9 420.2 506.4 405.5 498C390.9 489.5 340 542.8 330.5 528.5C321 514.2 315.1 455.1 298.5 451C281.9 446.9 221.635 475 197.5 444.5C173.365 414 236.5 277.1 197.5 231.5C158.5 185.9 5.05738e-07 72 5.05738e-07 21C5.05738e-07 7 -3.30896e-06 16.5 5.05738e-07 0H450V551.5Z',
					},
					{
						value: 'M450 225C432.3 226.4 414.6 227.8 399.9 219.4C385.3 210.9 373.6 192.7 364.1 178.4C354.6 164.1 347.2 153.8 330.6 149.7C314 145.6 288.3 147.8 279.2 136.2C270.1 124.7 277.6 99.5 280.6 81.6C283.6 63.6 282.1 53 272.1 40.6C262.1 28.2 243.5 14.1 225 0H450V225Z',
					},
					{
						value: 'M450 0.995318C449.921 1.00151 449.843 1.0077 449.777 0.970546C449.712 0.932945 449.66 0.852435 449.618 0.789177C449.576 0.725919 449.543 0.680355 449.469 0.662219C449.396 0.644082 449.281 0.653814 449.241 0.602499C449.2 0.551628 449.234 0.440152 449.247 0.360969C449.26 0.281343 449.254 0.234453 449.209 0.1796C449.165 0.124747 449.082 0.0623733 449 0H450V0.995318Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			100
		);
		closeAnimation.add(
			{
				targets: '.menuWave3',
				d: [
					{
						value: 'M450 657C426.4 658.9 422.1 595.2 402.5 584C383 572.7 331 636 303 605.5C275 575 301 523.563 268 515.5C235 507.437 186.68 503.5 159 468.5C131.32 433.5 190.5 351.5 177.5 289.5C164.5 227.5 -1.9322e-05 130 7.05136e-07 77.5C2.93312e-06 56.5 -1.67905e-06 25.5 7.05136e-07 0H450V657Z',
					},
					{
						value: 'M450 300C426.4 301.9 402.8 303.7 383.2 292.5C363.7 281.2 348.1 256.9 335.5 237.9C322.8 218.8 312.9 205.1 290.8 199.6C268.7 194.2 234.4 197 222.2 181.6C210.1 166.3 220.1 132.7 224.2 108.8C228.2 84.9 226.1 70.7 212.8 54.1C199.4 37.6 174.7 18.8 150 0H450V300Z',
					},
					{
						value: 'M450 0.995305C449.921 1.00161 449.843 1.00758 449.777 0.970423C449.712 0.932933 449.66 0.852313 449.618 0.789277C449.576 0.725909 449.543 0.680457 449.469 0.66221C449.396 0.644294 449.281 0.653584 449.241 0.602491C449.2 0.551731 449.234 0.440257 449.247 0.360964C449.261 0.281671 449.254 0.23456 449.209 0.179487C449.165 0.124745 449.082 0.0623725 449 0H450V0.995305Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			150
		);
		closeAnimation.add(
			{
				targets: '.menuWave2',
				d: [
					{
						value: 'M450 753C420.5 755.3 398.4 670 374 656C349.5 641.9 289.4 679.8 273.5 656C257.6 632.2 262.1 558.3 234.5 551.5C206.9 544.7 131 534.918 103.5 500C76 465.082 146.5 341.5 131 305.5C115.5 269.5 -7.26576e-06 198 1.05349e-05 136.5C1.90734e-05 107 -7.5849e-06 26.5 1.05349e-05 0H450V753Z',
					},
					{
						value: 'M450 375C420.5 377.3 391 379.6 366.6 365.6C342.1 351.5 322.7 321.1 306.8 297.3C290.9 273.5 278.6 256.3 251 249.5C223.4 242.7 180.5 246.3 165.3 227.1C150.1 207.8 162.7 165.8 167.7 136C172.7 106.1 170.2 88.3 153.5 67.7C136.8 47 105.9 23.5 75 0H450V375Z',
					},
					{
						value: 'M450 0.995413C449.921 1.00152 449.843 1.00762 449.778 0.970462C449.712 0.933034 449.661 0.852339 449.618 0.789164C449.576 0.725988 449.543 0.680332 449.469 0.662282C449.396 0.644232 449.281 0.653788 449.241 0.602822C449.2 0.551592 449.234 0.440105 449.247 0.361003C449.261 0.281636 449.254 0.234387 449.209 0.179705C449.165 0.124758 449.082 0.0623792 449 0H450V0.995413Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			200
		);
		closeAnimation.add(
			{
				targets: '.menuWave1',
				d: [
					{
						value: 'M450 821.5C414.6 824.3 390.3 731.4 361 714.5C331.6 697.7 265.793 747.799 234.5 714.5C195.5 673 223.2 597.2 190 589C156.9 580.9 99.5606 580.5 64.5 536C29.4394 491.5 74.5 394.4 80.5 358.5C90.2772 300 7.82794e-06 266 1.83184e-05 200.5C1.17488e-05 168.5 -1.21992e-05 40 7.82794e-06 0H450V821.5Z',
					},
					{
						value: 'M450 450C414.6 452.8 379.2 455.6 349.9 438.7C320.5 421.9 297.2 385.3 278.2 356.8C259.1 328.2 244.4 307.6 211.2 299.4C178.1 291.3 126.5 295.5 108.3 272.5C90.1 249.4 105.2 199 111.2 163.1C117.3 127.3 114.2 106 94.2 81.2C74.1 56.5 37.1 28.2 0 0H450V450Z',
					},
					{
						value: 'M450 0.99533C449.921 1.00152 449.843 1.00772 449.778 0.970336C449.712 0.933177 449.66 0.852224 449.618 0.789186C449.576 0.725928 449.543 0.680364 449.469 0.662226C449.396 0.64431 449.281 0.6536 449.241 0.602728C449.2 0.551634 449.234 0.440157 449.247 0.360752C449.261 0.281568 449.254 0.234456 449.209 0.179602C449.165 0.124969 449.082 0.062374 449 0H450V0.99533Z',
					},
				],
				easing: 'linear',
				duration: animationTime,
			},
			250
		);
		closeAnimation.finished.then(() => {
			setOpen('closed');
			document
				.getElementsByClassName('open-menu')[0]
				.classList.remove('z-10');
			document
				.getElementsByClassName('open-menu')[0]
				.classList.add('hidden');
		});
	};

	const eventHandler = () => {
		if (isOpen === 'open') {
			closeMenu();
		} else if (isOpen === 'closed') {
			openMenu();
		}
	};

	return (
		<div className="menu-container md:hidden">
			<button
				className="menu-button fixed z-20 right-0 flex mt-6 mr-6"
				onClick={eventHandler}
			>
				<svg
					id="menu-button-svg"
					className="h-16 w-16"
					viewBox="0 0 160 160"
					preserveAspectRatio="none"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="button-background"
						d="M135 0H25C11.1929 0 0 11.1929 0 25V135C0 148.807 11.1929 160 25 160H135C148.807 160 160 148.807 160 135V25C160 11.1929 148.807 0 135 0Z"
						fill="#3c162f"
					/>
					<path
						className="button-lines"
						d="M20 88C40 88 40 72 60 72C80 72 80 88 100 88C120 88 120 72 140 72"
						stroke="white"
						strokeWidth="6"
						strokeLinecap="round"
					/>
					<path
						className="button-lines"
						d="M20 129C40 129 40 113 60 113C80 113 80 129 100 129C120 129 120 113 140 113"
						stroke="white"
						strokeWidth="6"
						strokeLinecap="round"
					/>
					<path
						className="button-lines"
						d="M20 47C40 47 40 31 60 31C80 31 80 47 100 47C120 47 120 31 140 31"
						stroke="white"
						strokeWidth="6"
						strokeLinecap="round"
					/>
				</svg>
			</button>

			<div className="hidden open-menu fixed w-screen h-screen">
				<svg
					className="menu-wave-svg absolute"
					viewBox="0 0 450 900"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g>
						<path
							className="menuWave1"
							d="M450 0.99533C449.921 1.00152 449.843 1.00772 449.778 0.970336C449.712 0.933177 449.66 0.852224 449.618 0.789186C449.576 0.725928 449.543 0.680364 449.469 0.662226C449.396 0.64431 449.281 0.6536 449.241 0.602728C449.2 0.551634 449.234 0.440157 449.247 0.360752C449.261 0.281568 449.254 0.234456 449.209 0.179602C449.165 0.124969 449.082 0.062374 449 0H450V0.99533Z"
							fill="#123D5B"
						/>
						<path
							className="menuWave2"
							d="M450 0.995413C449.921 1.00152 449.843 1.00762 449.778 0.970462C449.712 0.933034 449.661 0.852339 449.618 0.789164C449.576 0.725988 449.543 0.680332 449.469 0.662282C449.396 0.644232 449.281 0.653788 449.241 0.602822C449.2 0.551592 449.234 0.440105 449.247 0.361003C449.261 0.281636 449.254 0.234387 449.209 0.179705C449.165 0.124758 449.082 0.0623792 449 0H450V0.995413Z"
							fill="#223556"
						/>
						<path
							className="menuWave3"
							d="M450 0.995305C449.921 1.00161 449.843 1.00758 449.777 0.970423C449.712 0.932933 449.66 0.852313 449.618 0.789277C449.576 0.725909 449.543 0.680457 449.469 0.66221C449.396 0.644294 449.281 0.653584 449.241 0.602491C449.2 0.551731 449.234 0.440257 449.247 0.360964C449.261 0.281671 449.254 0.23456 449.209 0.179487C449.165 0.124745 449.082 0.0623725 449 0H450V0.995305Z"
							fill="#2E2C4E"
						/>
						<path
							className="menuWave4"
							d="M450 0.995318C449.921 1.00151 449.843 1.0077 449.777 0.970546C449.712 0.932945 449.66 0.852435 449.618 0.789177C449.576 0.725919 449.543 0.680355 449.469 0.662219C449.396 0.644082 449.281 0.653814 449.241 0.602499C449.2 0.551628 449.234 0.440152 449.247 0.360969C449.26 0.281343 449.254 0.234453 449.209 0.1796C449.165 0.124747 449.082 0.0623733 449 0H450V0.995318Z"
							fill="#362343"
						/>
						<path
							className="menuWave5"
							d="M450 0.99538C449.921 1.00135 449.843 1.00799 449.777 0.970164C449.712 0.933003 449.661 0.852045 449.618 0.789005C449.576 0.725964 449.543 0.680176 449.469 0.66226C449.396 0.644343 449.281 0.653633 449.241 0.602537C449.2 0.551441 449.234 0.439958 449.247 0.360991C449.261 0.281361 449.254 0.234246 449.209 0.179832C449.165 0.124754 449.083 0.0623772 449 0H450V0.99538Z"
							fill="#3B1A36"
						/>
						<path
							className="menuWave6"
							d="M450 0.995264C449.921 1.0019 449.843 1.00721 449.777 0.97005C449.712 0.932894 449.66 0.851946 449.619 0.789576C449.576 0.725879 449.543 0.68076 449.469 0.662182C449.396 0.643604 449.281 0.65422 449.241 0.602466C449.2 0.55204 449.233 0.44057 449.247 0.360949C449.26 0.281328 449.253 0.234882 449.209 0.179147C449.165 0.12474 449.083 0.0623699 449 0H450V0.995264Z"
							fill="#3C162F"
						/>
					</g>
				</svg>

				<ul className="menu-links fixed flex-col items-center text-white text-lg h-screen w-screen ml-screen-w hidden pt-24 px-6">
					<li className="w-full">
						<a className="btn bg-light-purple w-full" href="#home">
							Home
						</a>
					</li>
					<li className="w-full pt-4">
						<a className="btn bg-light-purple w-full" href="#work">
							Work
						</a>
					</li>
					<li className="w-full pt-4">
						<a
							className="btn bg-light-purple w-full"
							href="#contact"
						>
							Contact
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MobileNav;
