const Button = (props) => {
	let containerStyle = ' relative h-fit rounded-lg hover:shadow-btn-r';

	let style =
		'btn-main--js min-h-[3rem] font-semibold text-btn px-4 rounded-lg select-none border-transparent uppercase hover:shadow-btn-l hover:bg-gradient-to-r';

	if (props.type === 'light-teal') {
		style +=
			' bg-light-teal hover:shadow-red-purple from-red-purple to-fuchsia-900';
		containerStyle += ' hover:shadow-fuchsia-900';
	}
	if (props.type === 'light-purple') {
		style += ' bg-light-purple hover:shadow-teal from-teal to-fuchsia-900';
		containerStyle += ' hover:shadow-fuchsia-900';
	}
	if (props.type === 'red-purple') {
		style += ' bg-red-purple hover:shadow-teal from-teal to-fuchsia-900';
		containerStyle += ' hover:shadow-fuchsia-900';
	}
	if (props.type === 'contact') {
		style += ' bg-light-teal hover:shadow-teal from-teal to-fuchsia-900';
		containerStyle += ' hover:shadow-fuchsia-900';
	}
	if (props.ml === true) {
		containerStyle += ' md:ml-8';
	}

	return (
		<div className={containerStyle}>
			<button className={style}>{props.children}</button>
		</div>
	);
};

export default Button;
