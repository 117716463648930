import React from 'react';
import heroImg from '../images/formal_circle.webp';
import Button from './buttons/Button';

const Home = () => {
	return (
		<div
			id="home"
			className="font-custom text-white justify-center relative h-80 flex px-6 pt-16 pb-6 md:pt-32 lg:px-8 lg:h-screen"
		>
			<div className="hidden lg:flex">
				<img
					className="max-w-md w-full h-max-content lg:pr-8"
					src={heroImg}
					alt="Garrett Spence wearing a blue plaid suit jacket"
				/>
			</div>
			<div className="flex relative justify-between flex-col max-w-2xl">
				<h1 className="font-semibold w-full">
					<span className="text-3xl">Garrett Spence,</span>
					<br />
					<span className="text-6xl leading-tight lg:text-8xl">
						web developer
					</span>
				</h1>
				<p className="text-white text-lg">
					Hi! I'm a full stack web developer and front-end JavaScript
					specialist based out of western Maryland. Currently I'm a
					freelance developer, creating and designing applications for
					small businesses and select individuals.
				</p>

				<div className="flex flex-col md:flex-row justify-between md:justify-start h-1/5">
					<Button type="red-purple">
						<a href="/files/garrett_spence_resume.docx" download>
							Check out my Resume
						</a>
					</Button>
					<Button type="light-purple" ml={true}>
						<a href="mailto:garrettwspence@gmail.com?subject=Portfolio Contact">
							Contact me
						</a>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Home;
