import { ReactComponent as GitHub } from '../images/icons/github.svg';
import { ReactComponent as Twitter } from '../images/icons/twitter.svg';
import { ReactComponent as LinkedIn } from '../images/icons/linkedin.svg';
import { ReactComponent as Email } from '../images/icons/email.svg';

import TopWave from './waves/TopWave';

const Footer = () => {
	return (
		<>
			<TopWave />

			<div className="w-full pb-8 flex justify-center bg-teal">
				<div className="w-10/12 flex justify-between md:max-w-2xl">
					<a
						className="github-link"
						href="https://github.com/GarrettSpence"
						aria-label="Garrett Spence Github"
					>
						<GitHub className="w-8 h-8 md:w-16 md:h-16 fill-white" />
					</a>
					<a
						className="twitter-link"
						href="https://twitter.com/BruncheonBro"
						aria-label="Garrett Spence Twitter"
					>
						<Twitter className="w-8 h-8 md:w-16 md:h-16 fill-white" />
					</a>
					<a
						className="linkedIn-link"
						href="https://www.linkedin.com/in/garrettspence/"
						aria-label="Garrett Spence LinkedIn"
					>
						<LinkedIn className="w-8 h-8 md:w-16 md:h-16 fill-white" />
					</a>
					<a
						className="email-link"
						href="mailto:garrettwspence@gmail.com"
						aria-label="Garrett Spence Email"
					>
						<Email className="w-8 h-8 md:w-16 md:h-16 fill-white" />
					</a>
				</div>
			</div>
		</>
	);
};

export default Footer;
