// Your web app's Firebase configuration
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyCzMuCpXlq2vSZ1VuUDNzk4_au4SNuNQXY',
	authDomain: 'gs-web-app.firebaseapp.com',
	databaseURL: 'https://gs-web-app.firebaseio.com',
	projectId: 'gs-web-app',
	storageBucket: 'gs-web-app.appspot.com',
	messagingSenderId: '1026017655991',
	appId: '1:1026017655991:web:955366461bcf53b48b6132',
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const db = getFirestore();
