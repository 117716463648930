import img from '../../images/clap.webp';

import { ReactComponent as Python } from '../../images/icons/python.svg';
import { ReactComponent as MySQL } from '../../images/icons/mysql.svg';
import Button from '../buttons/Button';

/**
 *
 * @returns Appalachian Upholstery completed work section
 */
const TheClapper = () => {
	return (
		<div className="pb-16">
			<h3 className="text-2xl font-medium pb-4">The Clapper</h3>
			<p className="pb-4 text-sm">
				A discord bot that claps. . . Alot . . .
			</p>
			<p className="pb-4 text-sm">
				Orignally developed as part of a joke, the bot gained some
				genuine popularity in a discord server and has been running
				since. Developed with python and the discord.py API wrapper.
			</p>
			<img
				src={img}
				alt="recent work"
				className="self-center rounded-xl mb-4 w-full max-w-[600px]"
			/>
			<div className="tech-stack pb-4">
				<h4 className="text-xl pb-4">Tech Stack</h4>
				<div className="grid grid-cols-5 md:grid-cols-6 gap-x-8 gap-y-5">
					<div className="flex flex-col items-center">
						<Python className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Python</p>
					</div>
					<div className="flex flex-col items-center">
						<MySQL className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">MySQL</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TheClapper;
