import img from '../../images/ajs_site.webp';

import { ReactComponent as Figma } from '../../images/icons/figma.svg';
import { ReactComponent as PhotoShop } from '../../images/icons/photoshop.svg';
import { ReactComponent as Vercel } from '../../images/icons/vercel.svg';
import { ReactComponent as Heroku } from '../../images/icons/heroku.svg';
import { ReactComponent as Strapi } from '../../images/icons/strapi.svg';
import { ReactComponent as Git } from '../../images/icons/git.svg';
import { ReactComponent as Html5 } from '../../images/icons/html5.svg';
import { ReactComponent as Ts } from '../../images/icons/typescript.svg';
import { ReactComponent as Next } from '../../images/icons/next.svg';
import { ReactComponent as Tailwind } from '../../images/icons/tailwind.svg';
import { ReactComponent as Toggl } from '../../images/icons/toggl.svg';
import { ReactComponent as ClickUp } from '../../images/icons/clickup.svg';
import Button from '../buttons/Button';

/**
 *
 * @returns Appalachian Upholstery completed work section
 */
const AJs = () => {
	return (
		<div className="pb-16">
			<h3 className="text-2xl font-medium pb-4">AJ's Cookie Jar</h3>
			<p className="pb-4 text-sm">
				A local bakery, specilizing in overloaded cookies and french
				macarons.
			</p>
			<p className="pb-4 text-sm">
				The site went through multiple design iterations, and ultimately
				landed on the the simplified "minimalistic" version that is live
				now. I am responsible for everything from initial design to
				final deployment and maintainance.
			</p>
			<img
				src={img}
				alt="recent work"
				className="self-center rounded-xl mb-4"
			/>
			<div className="tech-stack pb-4">
				<h4 className="text-xl pb-4">Tech Stack</h4>
				<div className="grid grid-cols-5 md:grid-cols-6 gap-x-8 gap-y-5">
					<div className="flex flex-col items-center">
						<Figma className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Figma</p>
					</div>
					<div className="flex flex-col items-center">
						<PhotoShop className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Photoshop</p>
					</div>
					<div className="flex flex-col items-center">
						<Next className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Next</p>
					</div>
					<div className="flex flex-col items-center">
						<Tailwind className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Tailwind</p>
					</div>
					<div className="flex flex-col items-center">
						<Git className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Git & Gitlab</p>
					</div>
					<div className="flex flex-col items-center">
						<Html5 className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">HTML5</p>
					</div>
					<div className="flex flex-col items-center">
						<Ts className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">TypeScript</p>
					</div>
					<div className="flex flex-col items-center">
						<Vercel className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Vercel</p>
					</div>
					<div className="flex flex-col items-center">
						<Heroku className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Heroku</p>
					</div>
					<div className="flex flex-col items-center">
						<Strapi className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Strapi CMS</p>
					</div>
					<div className="flex flex-col items-center">
						<Toggl className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Toggl</p>
					</div>
					<div className="flex flex-col items-center">
						<ClickUp className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">ClickUp</p>
					</div>
				</div>
			</div>

			<div className="project-links flex flex-col items-center">
				<div className="pb-6">
					<Button type="light-teal">
						<a
							href="https://www.ajscookiejar.com"
							target="_blank"
							rel="noreferrer"
						>
							Visit the live site
						</a>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AJs;
