import img from '../../images/auchair_site.webp';

import { ReactComponent as Bootstrap } from '../../images/icons/bootstrap.svg';
import { ReactComponent as Figma } from '../../images/icons/figma.svg';
import { ReactComponent as Firebase } from '../../images/icons/firebase.svg';
import { ReactComponent as Firestore } from '../../images/icons/firestore.svg';
import { ReactComponent as Git } from '../../images/icons/git.svg';
import { ReactComponent as Html5 } from '../../images/icons/html5.svg';
import { ReactComponent as Js } from '../../images/icons/js.svg';
import { ReactComponent as React } from '../../images/icons/react.svg';
import { ReactComponent as Sass } from '../../images/icons/sass.svg';
import { ReactComponent as Toggl } from '../../images/icons/toggl.svg';
import { ReactComponent as Trello } from '../../images/icons/trello.svg';
import Button from '../buttons/Button';

/**
 *
 * @returns Appalachian Upholstery completed work section
 */
const AUChair = () => {
	return (
		<div className="">
			<h3 className="text-2xl font-medium pb-4">
				Appalachian Upholstery
			</h3>
			<p className="pb-4 text-sm">
				An upholstery business site showcasing recent work, with a
				possible e-commerce store front coming soon.
			</p>
			<p className="pb-4 text-sm">
				The site was one of my first web-apps ever released; originally
				created as a single page app in pure HTML and CSS. It has been
				redesigned and updated multiple times to get it to where it is
				now as a mobile friendly PWA.
			</p>
			<img
				src={img}
				alt="recent work"
				className="self-center rounded-xl mb-4"
			/>
			<div className="tech-stack pb-4">
				<h4 className="text-xl pb-4">Tech Stack</h4>
				<div className="grid grid-cols-5 md:grid-cols-6 gap-x-8 gap-y-5">
					<div className="flex flex-col items-center">
						<Bootstrap className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Bootstrap</p>
					</div>
					<div className="flex flex-col items-center">
						<Figma className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Figma</p>
					</div>
					<div className="flex flex-col items-center">
						<Firebase className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Firebase</p>
					</div>
					<div className="flex flex-col items-center">
						<Firestore className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Firestore</p>
					</div>
					<div className="flex flex-col items-center">
						<Git className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Git & Gitlab</p>
					</div>
					<div className="flex flex-col items-center">
						<Html5 className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">HTML5</p>
					</div>
					<div className="flex flex-col items-center">
						<Js className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">ES6</p>
					</div>
					<div className="flex flex-col items-center">
						<React className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">React</p>
					</div>
					<div className="flex flex-col items-center">
						<Sass className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Sass</p>
					</div>
					<div className="flex flex-col items-center">
						<Toggl className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Toggl</p>
					</div>
					<div className="flex flex-col items-center">
						<Trello className="fill-current w-full h-auto md:w-12 md:h-12" />
						<p className="text-center text-sm pt-2">Trello</p>
					</div>
				</div>
			</div>

			<div className="project-links flex flex-col items-center">
				<div className="pb-6">
					<Button type="light-teal">
						<a
							href="https://www.auchair.com"
							target="_blank"
							rel="noreferrer"
						>
							Visit the live site
						</a>
					</Button>
				</div>
				<Button type="light-teal">
					<a
						href="https://github.com/Pseudo-Solutions-LLC/AppalachianUpholstery"
						target="_blank"
						rel="noreferrer"
					>
						Check out the code
					</a>
				</Button>
			</div>
		</div>
	);
};

export default AUChair;
