/**
 * A cool star parallax background
 * @returns jsx
 */
const Stars = () => {
	return (
		<div className="absolute -z-1">
			<div id="stars"></div>
			<div id="stars2"></div>
			<div id="stars3"></div>
		</div>
	);
};

export default Stars;
