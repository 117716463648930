import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const Nav = () => {
	return (
		<div className="nav-container">
			<DesktopNav />
			<MobileNav />
		</div>
	);
};

export default Nav;
