import React, { useEffect } from 'react';
import anime from 'animejs';

const BottomWave = () => {
	useEffect(() => {
		anime({
			targets: '#bWave #bWave1',
			d: [
				{
					value: 'M159.5 280C132.4 279.83 122.483 247.026 79.5 280C43 308 27.3362 164.081 0 177V0.000152588H900V291.5C882.5 296.5 863 299.5 839.5 291.5C806.895 280.401 782.022 225.5 762 219.5C736 211.709 694.285 236.314 668.5 226C641 215 614.85 227.442 588 219.5C552.5 209 555.5 213 519 226C495.734 234.286 441.8 205.321 414.5 215C387.2 224.679 377.8 280.726 350.5 286.5C323.2 292.104 298 246 248.5 237C212.097 230.381 186.7 280.34 159.5 280Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
		});
		anime({
			targets: '#bWave #bWave2',
			d: [
				{
					value: 'M180.5 178C153.4 177.83 152.3 205.679 125 232C89.0033 275.209 28 227.5 0 227.5V3.05176e-05H900V241C861.5 208.5 838.994 221.006 809 251C788 272 747.6 264.151 720.5 256C693.3 247.849 676.401 234.955 648.5 241C618.5 247.5 601.85 255.442 575 247.5C539.5 237 527 219.5 495 241C474.5 254.773 456.5 276 423 272C389.5 268 361.3 187.726 334 193.5C306.7 199.104 304.956 204.433 280.5 188.5C247.5 167 207.7 178.34 180.5 178Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 500,
		});
		anime({
			targets: '#bWave #bWave3',
			d: [
				{
					value: 'M188 181C157.5 197.5 152.5 233.5 111 239.5C54.3764 247.687 21 202.5 0 220.5V9.15527e-05H900V203C866 192 865.039 190.606 823 196C795.7 201.087 752.6 236.761 725.5 230.5C698.3 224.239 665.017 213.805 651 192C637.5 171 592.8 125.674 565.5 130.5C528 130.5 518.5 187 488 196C465.935 202.511 442 181 423.5 175C380 160.892 380.3 166.565 353 171C325.7 175.304 301.5 199 266.5 175C231.5 151 218.5 164.5 188 181Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 1000,
		});
		anime({
			targets: '#bWave #bWave4',
			d: [
				{
					value: 'M188.5 167C161.4 166.87 130 142 93.5 136.5C36.9264 127.975 27.3362 182.577 0 192.5V6.10352e-05H900V171C866 160 853.474 159.5 822 136.5C796 117.5 783 111.5 753 115.5C719.5 119.967 701 97.5 677 115.5C649.258 136.306 599 159.5 571 171C536.312 185.247 510.5 142 484 130.5C458.857 119.589 470.3 99.5654 443 107C415.7 114.435 402.8 98.0653 375.5 102.5C348.2 106.804 315 177 288.5 162C262 147 215.7 167.261 188.5 167Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 1500,
		});
		anime({
			targets: '#bWave #bWave5',
			d: [
				{
					value: 'M170 123C142.9 122.87 130.5 88.5488 90 95.0001C33.5 104 27.3362 113.077 0 123V9.15527e-05H900V131C866.327 120.106 858.13 114.116 827.4 91.6581L826.5 91.0001C800.5 72.0001 769.5 83.0001 739.5 87.0001C706 91.4668 664.2 137.087 637 123C609.9 108.914 601.3 108.674 574 113.5C536.5 113.5 516.96 63.586 487 71.5001C460.5 78.5002 460.3 87.5655 433 95.0001C405.7 102.435 382.464 155.718 357.5 140C330.5 123 274.5 165 254.5 140C225.984 104.356 197.2 123.261 170 123Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 2000,
		});
		anime({
			targets: '#bWave #bWave6',
			d: [
				{
					value: 'M171.455 86.4951C145.203 86.3705 137.502 86.334 98.5 75.5001C44.5 60.5001 30 60.5001 0 60.5001V0.000137329H900V26C868 21 859 21 825.5 34C795.479 45.6501 763 50 733 54C699.5 58.4667 679.2 112.587 652 98.5C624.9 84.4134 600.8 33.6741 573.5 38.5C536 38.5 523.96 58.586 494 66.5001C467.5 73.5001 457.3 68.0655 430 75.5001C402.7 82.9347 379.3 87.5654 352 92C324.7 96.3043 294.76 133.766 259 120.5C228 109 199.7 86.7609 172.5 86.5L171.455 86.4951Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 2500,
		});
	}, []);

	return (
		<svg
			id="bWave"
			viewBox="0 0 900 300"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			version="1.1"
			className="h-40vh min-w-full md:h-unset"
		>
			<g>
				<path
					id="bWave1"
					d="M166.5 198.5C139.4 198.33 123.3 201.179 96 227.5C60.0033 270.709 27.3362 283.581 0 296.5V-3.05176e-05H900V246.5C875 278.5 869 278 827 290.5C795.904 299.755 743.522 287 723.5 281C697.5 273.209 678.285 263.314 652.5 253C625 242 614.85 227.442 588 219.5C552.5 209 529.5 246.5 493 259.5C469.734 267.786 436.3 236.821 409 246.5C381.7 256.179 356.3 290.727 329 296.5C301.7 302.104 278.6 287.811 251.5 253C224.3 218.189 193.7 198.84 166.5 198.5Z"
					fill="#3c162f"
				></path>
				<path
					id="bWave2"
					d="M177 227.5C149.9 227.33 119.3 188.495 92 214.816C56.0033 258.025 27.3362 228.081 0 241V3.05176e-05H900V202C878.216 237.471 844.539 264.977 802.5 272C775.2 278.623 755.6 243.797 728.5 235.646C701.3 227.495 675.7 259.34 648.5 241C621.4 222.661 587.35 179.942 560.5 172C525 161.5 509 172 477 193.5C456.5 207.273 443.8 231.321 416.5 241C389.2 250.679 361.3 187.726 334 193.5C306.7 199.104 307.6 223.311 280.5 188.5C253.3 153.689 204.2 227.84 177 227.5Z"
					fill="#391f3d"
				></path>
				<path
					id="bWave3"
					d="M167 194.5C139.9 194.37 121 181 80 200C28.0907 224.056 27.3362 177.077 0 187V7.62939e-05H900V214.5C866 203.5 865.039 181.606 823 187C795.7 192.087 757.6 163.261 730.5 157C703.3 150.739 685.7 205.587 658.5 191.5C631.4 177.413 592.8 125.674 565.5 130.5C528 130.5 505.899 127.122 483 148C466 163.5 439 193 420.5 187C377 172.892 345.3 210.065 318 214.5C290.7 218.804 274.6 207.738 247.5 181C220.3 154.262 194.2 194.761 167 194.5Z"
					fill="#332849"
				></path>
				<path
					id="bWave4"
					d="M174.5 81C147.4 80.8696 130 142 93.5 136.5C36.9264 127.975 27.3362 144.577 0 154.5V6.10352e-05H900V171C866 160 853.474 159.5 822 136.5C796 117.5 782.5 120 752.5 124C719 128.467 695 177 677 162C650.36 139.8 591 130.5 563 142C528.312 156.247 510.5 142 484 130.5C458.857 119.589 453.3 129.065 426 136.5C398.7 143.935 374.3 109.065 347 113.5C319.7 117.804 279.6 168.738 252.5 142C225.3 115.262 201.7 81.2609 174.5 81Z"
					fill="#283052"
				></path>
				<path
					id="bWave5"
					d="M172.5 95C145.4 94.8696 130.5 88.5487 90 95C33.5 104 27.3362 61.5769 0 71.5V6.10352e-05H900V131C866.327 120.106 858.13 114.116 827.4 91.658L826.5 91C800.5 72 769.5 83 739.5 87C706 91.4667 664.2 137.087 637 123C609.9 108.913 601.3 74.174 574 79C536.5 79 516.96 63.5859 487 71.5C460.5 78.5 460.3 87.5654 433 95C405.7 102.435 372.3 82.5653 345 87C317.7 91.3042 279.1 149.738 252 123C224.8 96.2616 199.7 95.2609 172.5 95Z"
					fill="#1b3959"
				></path>
				<path
					id="bWave6"
					d="M174.5 75.5C147.4 75.3696 135.5 54.0487 95 60.5C38.5 69.5 30 33 0 33V5.34058e-05H900V52.5C881.5 75.5 857.474 94.5 826 71.5C800 52.5 773 71.5 743 75.5C709.5 79.9667 691.2 80.5866 664 66.5C636.9 52.4134 600.8 61.674 573.5 66.5C536 66.5 517.96 31.5859 488 39.5C461.5 46.5 457.3 68.0654 430 75.5C402.7 82.9346 372.8 67.0653 345.5 71.5C318.2 75.8042 283.1 82.2384 256 55.5C228.8 28.7616 201.7 75.7609 174.5 75.5Z"
					fill="#08415c"
				></path>
			</g>
		</svg>
	);
};

export default BottomWave;
