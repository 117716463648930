import React, { useEffect } from 'react';
import anime from 'animejs';

const TopWave = () => {
	useEffect(() => {
		anime({
			targets: '#tWave #tWave1',
			d: [
				{
					value: 'M190.5 25C163.4 25.1306 118.3 32.7449 91 12.5C55.0033 -20.7351 27.3362 120.437 0 110.5V294.417H900V19C878.216 -8.28313 882.039 59.4018 840 54C812.7 48.9061 807.1 32.2306 780 38.5C752.8 44.7694 721.2 53.3939 694 67.5C666.9 81.6061 638.3 29.8327 611 25C583.7 20.1673 536.3 63.6796 509 59.5C481.7 55.3204 470.3 7.94491 443 0.5C415.7 -6.94491 387.3 99.9408 360 95.5C332.7 91.1898 301.6 59.2244 274.5 86C247.3 112.776 217.7 24.7388 190.5 25Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
		});
		anime({
			targets: '#tWave #tWave2',
			d: [
				{
					value: 'M180.5 122C153.4 122.17 152.3 94.3206 125 68C89.0033 24.7908 28 72.5 0 72.5V300H900V59C861.5 91.5 838.994 78.9942 809 49C788 28 747.6 35.8491 720.5 44C693.3 52.1509 676.401 65.0452 648.5 59C618.5 52.5 601.85 44.5584 575 52.5C539.5 63 527 80.5 495 59C474.5 45.2266 456.5 24 423 28C389.5 32 361.3 112.274 334 106.5C306.7 100.896 304.956 95.5666 280.5 111.5C247.5 133 207.7 121.66 180.5 122Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 500,
		});
		anime({
			targets: '#tWave #tWave3',
			d: [
				{
					value: 'M188 119C157.5 102.5 152.5 66.5 111 60.5C54.3764 52.3134 21 97.5 0 79.5V300H900V97C866 108 865.039 109.394 823 104C795.7 98.9132 752.6 63.2393 725.5 69.5C698.3 75.7607 665.017 86.1953 651 108C637.5 129 592.8 174.326 565.5 169.5C528 169.5 518.5 113 488 104C465.935 97.4891 442 119 423.5 125C380 139.108 380.3 133.435 353 129C325.7 124.696 301.5 101 266.5 125C231.5 149 218.5 135.5 188 119Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 1000,
		});
		anime({
			targets: '#tWave #tWave4',
			d: [
				{
					value: 'M188.5 133C161.4 133.13 130 158 93.5 163.5C36.9264 172.025 27.3362 117.423 0 107.5V300H900V129C866 140 853.474 140.5 822 163.5C796 182.5 783 188.5 753 184.5C719.5 180.033 701 202.5 677 184.5C649.258 163.694 599 140.5 571 129C536.312 114.753 510.5 158 484 169.5C458.857 180.411 470.3 200.435 443 193C415.7 185.565 402.8 201.935 375.5 197.5C348.2 193.196 315 123 288.5 138C262 153 215.7 132.739 188.5 133Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 1500,
		});
		anime({
			targets: '#tWave #tWave5',
			d: [
				{
					value: 'M170 177C142.9 177.13 130.5 211.451 90 205C33.5 196 27.3362 186.923 0 177V300H900V169C866.327 179.894 858.13 185.884 827.4 208.342L826.5 209C800.5 228 769.5 217 739.5 213C706 208.533 664.2 162.913 637 177C609.9 191.086 601.3 191.326 574 186.5C536.5 186.5 516.96 236.414 487 228.5C460.5 221.5 460.3 212.435 433 205C405.7 197.565 382.464 144.282 357.5 160C330.5 177 274.5 135 254.5 160C225.984 195.644 197.2 176.739 170 177Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 2000,
		});
		anime({
			targets: '#tWave #tWave6',
			d: [
				{
					value: 'M171.455 213.505C145.203 213.63 137.502 213.666 98.5 224.5C44.5 239.5 30 239.5 0 239.5V300H900V274C868 279 859 279 825.5 266C795.479 254.35 763 250 733 246C699.5 241.533 679.2 187.413 652 201.5C624.9 215.587 600.8 266.326 573.5 261.5C536 261.5 523.96 241.414 494 233.5C467.5 226.5 457.3 231.935 430 224.5C402.7 217.065 379.3 212.435 352 208C324.7 203.696 294.76 166.234 259 179.5C228 191 199.7 213.239 172.5 213.5L171.455 213.505Z',
				},
			],
			easing: 'easeInOutSine',
			duration: 4000,
			loop: true,
			direction: 'alternate',
			delay: 2500,
		});
	}, []);

	return (
		<svg
			id="tWave"
			viewBox="0 0 900 300"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			version="1.1"
			className="h-40vh min-w-full md:h-unset"
		>
			<g>
				<path
					id="tWave1"
					d="M163.8 103.31C136.7 103.44 109.3 81.7886 82 61.5718C46.0033 28.3828 27.3362 13.8444 0 3.92122V300H900V54.7894C878.216 27.5441 860.039 18.7066 818 13.3123C790.7 8.22545 763.3 16.573 736.2 22.8337C709 29.0944 682 33.5291 654.8 47.6157C627.7 61.7022 600.3 85.7016 573 80.8756C545.7 76.0497 518.3 42.6593 491 38.4855C463.7 34.3117 436.3 59.6153 409 52.1808C381.7 44.7462 354.3 4.83424 327 0.399575C299.7 -3.90465 272.3 27.3988 245.2 54.1372C218 80.8756 191 103.049 163.8 103.31Z"
					fill="#3c162f"
				></path>
				<path
					id="tWave2"
					d="M177 72.5C149.9 72.6698 119.3 111.505 92 85.1844C56.0033 41.9752 27.3362 71.9191 0 59V300H900V98C878.216 62.529 844.539 35.023 802.5 28C775.2 21.3774 755.6 56.2029 728.5 64.3538C701.3 72.5047 675.7 40.6605 648.5 59C621.4 77.3395 587.35 120.058 560.5 128C525 138.5 509 128 477 106.5C456.5 92.7266 443.8 68.6792 416.5 59C389.2 49.3208 361.3 112.274 334 106.5C306.7 100.896 307.6 76.6889 280.5 111.5C253.3 146.311 204.2 72.1604 177 72.5Z"
					fill="#391f3d"
				></path>
				<path
					id="tWave3"
					d="M167 105.5C139.9 105.63 121 119 80 100C28.0907 75.9444 27.3362 122.923 0 113V300H900V85.5C866 96.5 865.039 118.394 823 113C795.7 107.913 757.6 136.739 730.5 143C703.3 149.261 685.7 94.4134 658.5 108.5C631.4 122.587 592.8 174.326 565.5 169.5C528 169.5 505.899 172.878 483 152C466 136.5 439 107 420.5 113C377 127.108 345.3 89.9347 318 85.5C290.7 81.1958 274.6 92.2617 247.5 119C220.3 145.738 194.2 105.239 167 105.5Z"
					fill="#332849"
				></path>
				<path
					id="tWave4"
					d="M174.5 219C147.4 219.13 130 158 93.5 163.5C36.9264 172.025 27.3362 155.423 0 145.5V300H900V129C866 140 853.474 140.5 822 163.5C796 182.5 782.5 180 752.5 176C719 171.533 695 123 677 138C650.36 160.2 591 169.5 563 158C528.312 143.753 510.5 158 484 169.5C458.857 180.411 453.3 170.935 426 163.5C398.7 156.065 374.3 190.935 347 186.5C319.7 182.196 279.6 131.262 252.5 158C225.3 184.738 201.7 218.739 174.5 219Z"
					fill="#283052"
				></path>
				<path
					id="tWave5"
					d="M172.5 205C145.4 205.13 130.5 211.451 90 205C33.5 196 27.3362 238.423 0 228.5V300H900V169C866.327 179.894 858.13 185.884 827.4 208.342L826.5 209C800.5 228 769.5 217 739.5 213C706 208.533 664.2 162.913 637 177C609.9 191.087 601.3 225.826 574 221C536.5 221 516.96 236.414 487 228.5C460.5 221.5 460.3 212.435 433 205C405.7 197.565 372.3 217.435 345 213C317.7 208.696 279.1 150.262 252 177C224.8 203.738 199.7 204.739 172.5 205Z"
					fill="#1b3959"
				></path>
				<path
					id="tWave6"
					d="M174.5 224.5C147.4 224.63 135.5 245.951 95 239.5C38.5 230.5 30 267 0 267V300H900V247.5C881.5 224.5 857.474 205.5 826 228.5C800 247.5 773 228.5 743 224.5C709.5 220.033 691.2 219.413 664 233.5C636.9 247.587 600.8 238.326 573.5 233.5C536 233.5 517.96 268.414 488 260.5C461.5 253.5 457.3 231.935 430 224.5C402.7 217.065 372.8 232.935 345.5 228.5C318.2 224.196 283.1 217.762 256 244.5C228.8 271.238 201.7 224.239 174.5 224.5Z"
					fill="#08415c"
				></path>
			</g>
		</svg>
	);
};

export default TopWave;
