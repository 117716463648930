import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import Stars from './components/sparkles/Stars';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Nav from './components/nav/Nav';

function App() {
	return (
		<Router>
			<Nav />
			<Stars />
			<Switch>
				<Route path="/">
					<Home />
					<Work />
					<Contact />
				</Route>
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;
