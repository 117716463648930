import AJs from './work/AJs';
import Dix from './work/Dix';
import AUChair from './work/AUChair';
import TheClapper from './projects/TheClapper';
import TopWave from './waves/TopWave';
import BottomWave from './waves/BottomWave';

/**
 *
 * @returns Recent work examples
 */
const Work = () => {
	return (
		<>
			<TopWave />
			<div id="work" className="bg-teal flex justify-center pt-16">
				<div className="relative flex justify-around text-white font-custom flex-col px-6 md:px-0 md:max-w-2xl lg:max-w-[80vw]">
					<h2 className="text-4xl font-semibold self-center pb-8">
						Recent Work
					</h2>
					{/* TODO: Refactor the icons in the tech stack to be a component */}
					<div className="lg:grid lg:grid-cols-2 lg:gap-x-20">
						<AJs />
						<Dix />
						<AUChair />
					</div>
				</div>
			</div>
			<div id="projects" className="bg-teal flex justify-center pt-16 lg:pt-40">
				<div className="relative flex justify-around text-white font-custom flex-col px-6 md:px-0 md:max-w-2xl lg:max-w-[80vw]">
					<h2 className="text-4xl font-semibold self-center pb-8">
						Projects
					</h2>
					<div className="lg:grid lg:grid-cols-2 lg:gap-x-20">
						<TheClapper />
					</div>
				</div>
			</div>
			<BottomWave />
		</>
	);
};

export default Work;
