import siteLogo from '../../images/site_logo.webp';

const DesktopNav = () => {
	return (
		<div className="navbar fixed py-2 mx-auto w-full z-10 bg-dark-gray text-white hidden md:block">
			<nav className="flex justify-between mx-auto max-w-5xl md:max-w-2xl lg:w-3/4 lg:max-w-6xl lg:px-8 lg:w-full">
				<span className="hidden w-10 md:block lg:mr-0 self-center">
					<a href="#home" aria-label="Logo">
						<img src={siteLogo} alt="Site logo" />
					</a>
				</span>
				<ul className="flex text-base">
					<li>
						<a
							className="btn hover:bg-dark-gray-hover h-auto"
							href="#work"
						>
							Work
						</a>
					</li>
					<li>
						<a
							className="btn hover:bg-dark-gray-hover h-auto"
							href="#projects"
						>
							Projects
						</a>
					</li>
					{/* <li>
						<a
							className="btn hover:bg-dark-gray-hover h-auto"
							href="#about"
						>
							About
						</a>
					</li> */}
					<li>
						<a
							className="btn hover:bg-dark-gray-hover h-auto"
							href="#contact"
						>
							Contact
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default DesktopNav;
